<template>
    <div>
        <el-dialog :visible.sync="dialogVisible" width="60%" center @close="close">
            <div class="tit">汇款凭证审核未通过</div>
            <div class="conc">
                <div class="title">未通过原因</div>
                <div class="text">{{ platformAuditRemark }}</div>
                <div class="time">2023-07-21 09:18:20</div>
            </div>
            <div class="btn cur" @click="repayment">重新付款或提交凭证</div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    props: ['dialogVisible','platformAuditRemark'],
    data() {
        return {

        }
    },
    methods: {
        close(){
            this.$emit('dialogVisibleShowClick')
        },
        repayment(){
            this.$emit('repaymentClick')
        }
    }
}
</script>

<style lang="less" scoped>
.tit {
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-family: PingFang SC-Heavy, PingFang SC;
    font-weight: 800;
    color: #FF4646;
    margin-bottom: 20px;
}

.conc {
    width: 532px;
    height: 181px;
    background: #F3F6F8;
    border-radius: 8px 8px 8px 8px;
    margin: 0 auto;
    padding: 20px 35px;
    box-sizing: border-box;

    >.title {
        font-size: 16px;
        font-family: PingFang SC-Heavy, PingFang SC;
        font-weight: 800;
        color: #FF4242;
    }

    >.text {
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin: 10px 0;
    }

    >.time {
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #999999;
    }
}

.btn {
    width: 180px;
    height: 40px;
    background: #FF4242;
    border-radius: 4px 4px 4px 4px;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
    line-height: 40px;
    margin: 0 auto;
    margin-top: 40px;
}


/deep/ .el-dialog__header {
    padding: 0px;
}
</style>