<template>
    <el-dialog title="申请开票" :visible.sync="outerVisible" @close="close" width="60%">

        <el-dialog width="50%" title="开票抬头" :visible.sync="innerVisible" append-to-body>
            <div class="xzCon">
                <div class="address" v-for="(i, index) in addressList" :key="index" @click="fpClick(i)">
                    <div class="content">
                        <div class="img cur">
                            <img v-if="i.isShow" src="@/assets/icon/xuanz.png" alt="">
                            <img v-else src="@/assets/icon/wxz.png" alt="">
                            <div class="info">
                                <div class="name">{{ i.invoiceTitle }}</div>
                                <div class="addr">{{ i.titleType == 1 ? '个人或事业单位' : i.taxNumber }}</div>
                            </div>
                        </div>


                        <div class="caoz">
                            <el-button type="text" style="color: #666666;" @click.stop="dele(i.id)">删除</el-button>
                            <el-button type="text" style="color: #333333;" @click.stop="edit(i)">编辑</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>

        <div class="con">

            <el-form ref="form" :model="form" label-width="100px" :rules="rules" label-position="left">
                <el-form-item label="订单编号">
                    <div>{{ paymentObj.oid }}</div>
                </el-form-item>
                <el-form-item label="抬头类型：" prop="titleType">
                    <el-select size="small" v-model="form.titleType" :value-key="'label'" placeholder="请选择">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="发票抬头" prop="invoiceTitle">
                    <el-input v-model="form.invoiceTitle" size="small"></el-input>
                </el-form-item>
                <el-form-item v-if="form.titleType == 2" label="发票税号" prop="taxNumber">
                    <el-input v-model="form.taxNumber" size="small"></el-input>
                </el-form-item>
                <el-form-item v-if="form.titleType == 2" label="开户银行">
                    <el-input v-model="form.bankName" size="small"></el-input>
                </el-form-item>
                <el-form-item v-if="form.titleType == 2" label="银行账号">
                    <el-input v-model="form.bankAccount" size="small"></el-input>
                </el-form-item>
                <el-form-item v-if="form.titleType == 2" label="企业地址">
                    <el-input v-model="form.companyAddress" size="small"></el-input>
                </el-form-item>
                <el-form-item v-if="form.titleType == 2" label="企业电话">
                    <el-input v-model="form.companyPhone" size="small"></el-input>
                </el-form-item>

            </el-form>
            <div class="xz cur" @click="innerVisible = true" v-if="addressList.length > 0"> 选择发票抬头 </div>
        </div>


        <div slot="footer" class="dialog-footer">
            <el-button @click="outerVisible = false">取 消</el-button>
            <el-button type="primary" @click="submitForm">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { getInvoiceList, putApplyInvoicing, editInvoice, postAddInvoice } from "@/utils/api/myApi/index"
export default {
    props: ['outerVisible', 'paymentObj'],
    data() {
        return {
            innerVisible: false,
            form: {
                bankAccount: "",  // 银行账号
                bankName: "",     // 开户银行
                companyAddress: "",     // 企业地址
                companyPhone: "",    // 企业电话
                invoiceTitle: "",     // 发票抬头
                taxNumber: "",   // 发票税号
                titleType: ''  // 抬头类型-
            },
            // 发票列表
            addressList: [],
            // 发票类型
            options: [
                {
                    label: '个人或事业单位',
                    value: '1'
                },
                {
                    label: '企业',
                    value: '2'
                }
            ],
            // 校验
            rules: {
                taxNumber: [
                    { required: true, message: '请输发票税号', trigger: 'blur' },
                    { min: 15, max: 20, message: '长度在 15 到 20 个字符', trigger: 'blur' }
                ],
                titleType: [
                    { required: true, message: '请选择抬头类型', trigger: 'change' },
                ],
                invoiceTitle: [
                    { required: true, message: '请输入发票抬头', trigger: 'blur' },
                ],
            }
        }
    },
    mounted() {
        this.getAddressList()
    },
    methods: {
        close() {
            this.$emit('outerVisibleShowClick')
            this.$refs.form.resetFields();
        },
        onSubmit() {
            console.log('submit!');
        },
        // 确定按钮
        submitForm() {
            this.$refs.form.validate(async (valid) => {
                if (valid) {
                    console.log('验证通过', this.paymentObj, this.form.id, this.paymentObj.oid)
                    if (this.form.id) {
                        // 走编辑
                        const res = await editInvoice(this.form)
                        if (res.data) {
                            this.getAddressList()
                        }
                    } else {
                        // 走新增
                        const res = await postAddInvoice(this.form)
                        if(res.data){
                            this.form.id = res.data
                            this.getAddressList()
                        }
                    }
                    const res = await putApplyInvoicing({
                        invoiceId: this.form.id,
                        oid: this.paymentObj.oid
                    })
                    if(res.data){
                        this.$message.success('已申请开票')
                        this.$emit('getOrderList')
                        this.close()
                    }
                } else {
                    console.log('error submit!!', valid);
                    return false;
                }
            });
        },
        // 获取发票列表
        async getAddressList() {
            console.log('获取发票列表')
            const res = await getInvoiceList({
                current: 1,
                size: 10
            })
            if (res.statusCode == 8201) {
                res.data.records.map(o => {
                    this.$set(o, 'isShow', false)
                })
                if (this.form.id) {
                    res.data.records.map(o => {
                        if (o.id == this.form.id) {
                            o.isShow = true
                            return
                        }
                    })
                }

                this.addressList = res.data.records
            }
        },
        // 选择发票信息
        fpClick(i) {
            this.addressList.map(o => o.isShow = false)
            this.addressList.map(d => {
                if (d.id == i.id) {
                    d.isShow = true
                    this.form.bankAccount = i.bankAccount
                    this.form.bankName = i.bankName
                    this.form.companyAddress = i.companyAddress
                    this.form.companyPhone = i.companyPhone
                    this.form.invoiceTitle = i.invoiceTitle
                    this.form.taxNumber = i.taxNumber
                    this.form.titleType = i.titleType
                    this.form.id = i.id
                    this.innerVisible = false
                    return
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body {
    padding: 0;
}

.con {
    width: auto;
    margin: 0 auto;
    display: flex;
    // flex-wrap: wrap;
    justify-content: center;

    >.xz {
        font-size: 12px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #FF4242;
        text-decoration: underline;
        margin-top: 135px;
        margin-left: 20px;
    }

}


.xzCon {
    width: 100%;
    padding: 0px 20px 50px 20px;
    box-sizing: border-box;

    >.address {
        width: 100%;
        height: auto;
        background-color: #fff;
        // padding: 12px;
        box-sizing: border-box;

        >.content {
            width: 100%;
            height: 70px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #dcdcdc;
            padding: 0 12px;
            box-sizing: border-box;
            justify-content: space-between;

            .img {
                display: flex;
                align-items: center;
            }

            .info {
                font-size: 12px;
                color: #666;
                margin-left: 10px;

                >.name {
                    font-size: 14px;
                    color: #000;
                    display: flex;
                    align-items: center;
                    margin-bottom: 5px;
                }
            }
        }

        >.operation {
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 15px;
            box-sizing: border-box;

        }
    }
}
</style>