<template>
    <div>
        <!-- 继续付款对话框 -->
        <el-dialog title="请确认订单" :visible.sync="centerDialogVisible2" width="50%" @close="close" >
            <div style="width: 100%;text-align: center;font-size: 30px;color: #f45311;">￥{{ paymentObj.paymentMoney }}
            </div>
            <div style="width: 100%;text-align: center;color: #999;margin: 10px 0;">订单编号：{{ paymentObj.oid }}</div>
            <div style="width: 100%;text-align: center;color: #999;">创建时间：{{ paymentObj.createTime }}</div>
            <div class="payment">
                <div class="title">支付方式</div>
                <div style="display: flex;position: relative;">
                    <div class="zf" @click="paymentType(index)" v-for="(i, index) in paymentArr" :key="index"
                        :class="[i.act ? 'actShow' : '']">
                        <div class="img">
                            <img :src="i.src" alt="">
                        </div>
                        <span>{{ i.name }}</span>
                        <transition name="fade" mode="out-in">
                            <div v-if="outInShow" class="xxhk" style="position: absolute;top: -25px;left: 415px;">
                                <div class="title">收款账号信息</div>
                                <div class="son">
                                    <div class="l">开户名称</div>
                                    <div class="z">{{ accountInfo.accountName }}</div>
                                    <div class="r cur" @click="copyText(accountInfo.accountName)">复制</div>
                                </div>
                                <div class="son">
                                    <div class="l">开户银行</div>
                                    <div class="z">{{ accountInfo.bankName }}</div>
                                </div>
                                <div class="son">
                                    <div class="l">汇款账号</div>
                                    <div class="z">{{ accountInfo.accountNumber }}</div>
                                    <div class="r cur" @click="copyText(accountInfo.accountNumber)">复制</div>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="centerDialogVisible2 = false">取 消</el-button>
                <el-button type="primary" @click="liJiZF">立即支付</el-button>
            </span>
        </el-dialog>

        <!-- 上传凭证对话框 -->
        <offlineHK :accountInfo="accountInfo" :HKshow="HKshow" :oid="oid" @HKshowCl="HKshowCl" />
    </div>
</template>

<script>
import offlineHK from "@/pages/myPage/offlineHK.vue";
import Clipboard from 'clipboard';
import { postSourcingPayment,getStoreAccount } from "@/utils/api/myApi/index"

export default {
    props: ['paymentObj','centerDialogVisible2'],
    components: {
        offlineHK
    },
    data() {
        return {
            HKshow:false,
            outInShow:false,
            oid:'',
            accountInfo:{},
            payType : 'WX',
             // 支付方式
             paymentArr: [
                {
                    name: '微信支付',
                    src: require('@/assets/icon/wxzf.png'),
                    act: true
                },
                {
                    name: '支付宝支付',
                    src: require('@/assets/icon/zfbzf.png'),
                    act: false
                },
                {
                    name: '线下汇款',
                    src: require('@/assets/icon/xxhk.png'),
                    act: false
                },
            ],
        }
    },
    mounted(){
        this.getStoreAccountById()
    },
    methods: {
        close(){
            console.log('关闭了')
            this.$emit('centerDialogVisible2Click')
        },
        HKshowCl() {
            console.log("zf组件")
            this.HKshow = false
            this.$emit('centerDialogVisible2Click')
        },
        // 获取汇款账户信息
        async getStoreAccountById() {
            const res = await getStoreAccount()
            if (res.statusCode == 8201) {
                this.accountInfo = res.data
            }
        },
        // 付款 H5
        async liJiZF() {
            if (this.payType == 'HK') {
                this.$emit('centerDialogVisible2Click')
                this.oid = this.paymentObj.oid
                console.log(this.oid)
                this.HKshow = true
                return
            }
            var oids = []
            oids.push(this.paymentObj.oid)
            if (this.payType == 'WX') {
                var parme = {
                    type: 'mall',
                    payType: 'WX',
                    tradeType: 'NATIVE',
                    oId: JSON.stringify(oids),
                }
            } else {
                var parme = {
                    type: 'mall',
                    payType: 'AliPay',
                    oId: JSON.stringify(oids),
                    returnUrl: this.$store.state.returnUrl // 内网无法访问，这里是支付完成后，跳转测试
                }
            }
            const res = await postSourcingPayment(parme)
            if (res.statusCode == 8201) {
                if (this.payType == 'WX') {
                    let couponNum = this.paymentObj.paymentMoney
                    this.$router.push(`/payment/WeChatPayPage?codeUrl=${res.data.codeUrl}&outTradeNo=${res.data.outTradeNo}&couponNum=${couponNum}`)
                } else {
                    var tempwindow = window.open('_blank')
                    tempwindow.location = res.data
                }
            }
        },
        // 复制
        copyText(shipNo) {
            const textToCopy = shipNo;
            const clipboard = new Clipboard('.el-button', {
                text: () => textToCopy
            });
            let that = this
            clipboard.on('success', () => {
                that.$message.success('复制成功')
                clipboard.destroy();
            });
            clipboard.on('error', () => {
                that.$message.error('复制失败')
                clipboard.destroy();
            });
            clipboard.onClick(event);
        },
        paymentType(index) {
            this.paymentArr.map(t => t.act = false)
            this.paymentArr[index].act = true
            if (index == 1) {
                this.payType = 'ZFB'
                this.outInShow = false
            } else if (index == 0) {
                this.payType = 'WX'
                this.outInShow = false
            } else if (index == 2) {
                this.outInShow = true
                // 线下汇款
                this.payType = 'HK'
            }
        },
    }
}
</script>

<style lang="less" scoped>


.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
.xxhk {
    width: 380px;
    height: 137px;
    padding: 12px 20px 12px 40px;
    box-sizing: border-box;
    background: url("../../assets/xxhkBg.png") no-repeat;
    background-size: 100% 100%;

    >.title {
        font-size: 14px;
        font-family: PingFang SC-Heavy, PingFang SC;
        font-weight: 800;
        color: #333333;
        margin-bottom: 10px;
    }

    >.son {
        width: 100%;
        display: flex;
        align-items: center;
        padding-right: 20px;
        box-sizing: border-box;
        margin-bottom: 7px;

        >.l {
            width: 63px;
            font-size: 12px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #666666;
        }

        >.z {
            font-size: 12px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            margin-right: 15px;
        }

        >.r {
            font-size: 12px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #666666;
        }
    }
}

.actShow {
    border: 1px solid #FF4242 !important;
    color: #FF4242 !important;
}
.payment {
    width: 100%;
    height: 115px;
    padding: 14px 28px;
    box-sizing: border-box;
    border: 1px solid #E0E0E0;
    margin-top: 20px;

    >.title {
        width: 100%;
        font-size: 16px;
        color: #333;
        font-weight: bold;
    }

    .zf {
        width: 120px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #E0E0E0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        margin-top: 17px;
        cursor: pointer;
        color: #000;

        >.img {
            width: 20px;
            height: 20px;
            margin-right: 8px;

            >img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        >span {
            font-size: 14px;
        }
    }
}
</style>
